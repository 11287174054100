import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=11aeaa8a"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=11aeaa8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketLandingMPLandingMenu: require('/usr/src/app/components/Market/Landing/MPLandingMenu.vue').default,MarketLandingMPLandingCartItems: require('/usr/src/app/components/Market/Landing/MPLandingCartItems.vue').default,MarketLandingMPLandingSlider: require('/usr/src/app/components/Market/Landing/MPLandingSlider.vue').default,MarketLandingMPLandingLinkBadges: require('/usr/src/app/components/Market/Landing/MPLandingLinkBadges.vue').default,MarketLandingMPLandingIconBadges: require('/usr/src/app/components/Market/Landing/MPLandingIconBadges.vue').default})
